<template>
  <CSidebar
    fixed
    size="sm"
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none bg-gray-200" to="/admin-x">
    <CImg :src="brandLogo" width="70%"/>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="navMenu"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="onClick()"
    />
  </CSidebar>
</template>

<script>
  const menu = [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/admin-x/dashboard',
          icon: 'cil-speedometer',
        },

        {
          _name: 'CSidebarNavDropdown',
          name: '제품관리',
          route: '/product/key',
          icon: 'cil-industry',
          items: [
            {
              name: '제품정보',
              to: '/admin-x/product/info'
            },
            {
              name: '제품키관리',
              to: '/admin-x/product/key'
            },
            {
              name: '인증토큰관리',
              to: '/admin-x/product/token'
            },
            {
              name: '쿠폰관리',
              to: '/admin-x/product/coupon'
            },
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: '판매관리',
          route: '/sales/sales',
          icon: 'cil-calculator',
          items: [
            {
              name: '패키지상품관리',
              to: '/admin-x/sales/master'
            },
            {
              name: '상품주문관리',
              to: '/admin-x/sales/order'
            },
            {
              name: '세금계산서관리',
              to: '/admin-x/sales/tax'
            },
            {
              name: '제품주문내역(구)',
              to: '/admin-x/sales/product'
            },
            {
              name: '주문업로드(구)',
              to: '/admin-x/sales/upload'
            },
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: '결제관리',
          route: '/payment/info',
          icon: 'cil-calculator',
          items: [
            {
              name: '카드결제내역',
              to: '/admin-x/payment/sales'
            },
            {
              name: '카드결제(구)',
              to: '/admin-x/payment/info'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: '회원관리',
          to: '/admin-x/member/xcost',
          icon: 'cil-find-in-page',
          items: [
            {name: '통합회원관리', to: '/admin-x/member/xcost'},
            {name: '커뮤니티 회원관리', to: '/admin-x/member/web'},
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: '설정',
          to: '/setting/user',
          icon: 'cil-settings',
          items: [
            {
              name: '오피스 사용자 관리',
              to: '/admin-x/setting/users'
            },
          ]
        },

      ]
    }
  ]

export default {
  name: 'TheSidebar',
  computed: {
    show () {
      return this.$store.state.adminStore.sidebarShow
    },
    minimize () {
      return this.$store.state.adminStore.sidebarMinimize
    },
  },
  data(){
    return {
      user: null,
      userNav: null,
      adminNav: null,
      navMenu: [],
      brandLogo : this.getCurrentLogo()
    }
  },
  created(){
    this.user = $user;
    this.navMenu = [];


    if( $user.userRole==='ROLE_ADMIN' ){
      this.setAdminMenu();
    }else{
      this.setUserMenu();
    }


  },
  methods: {
    onClick() {
      this.$store.commit('set', ['sidebarMinimize', !this.minimize]);
      this.brandLogo = this.getCurrentLogo();
    },
    getCurrentLogo() {
      return (this.$store.state.adminStore.sidebarMinimize) ? 'images/xcost-logo.png' : 'images/xcost-admin.png';
    },
    setUserMenu(){
      console.log( '**************** TheSidebar ************** -------------------------setUserMenu()-----------' );
      this.navMenu = menu;
    },

    setAdminMenu(){
      console.log( '**************** TheSidebar ************** -------------------------setAdminMenu()-----------' );
      this.navMenu = menu;

    },


  },




}
</script>
